body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dropzone {
  border: 2px dashed #5a70f0;
  border-radius: 10px;
  width: 700px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #999;
}

.dropzone:hover {
  background-color: #fafafa;
}

.dropzone p {
  margin: 0 0 10px 0;
}

.dropzone button {
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #2196f3;
  cursor: pointer;
}

.dropzone button:hover {
  background-color: #1565c0;
}
.file-upload-btn {
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #2196f3;
  cursor: pointer;
}

.file-upload-btn:hover {
  background-color: #1565c0;
}


.dropzone-pas-3 {
  border: 2px dashed #F40B27;
  border-radius: 10px;
  width: 700px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #999;
}

.dropzone-pas-3:hover {
  background-color: #fafafa;
}

.dropzone-pas-3 p {
  margin: 0 0 10px 0;
}

.dropzone-pas-3 button {
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #F40B27;
  cursor: pointer;
}

.dropzone-pas-3 button:hover {
  background-color: #1565c0;
}

.dropzone-mca {
  border: 2px dashed #BC00A3;
  border-radius: 10px;
  width: 700px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #999;
}

.dropzone-mca:hover {
  background-color: #fafafa;
}

.dropzone-pas-3 p {
  margin: 0 0 10px 0;
}

.dropzone-mca button {
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color:#BC00A3;
  cursor: pointer;
}

.dropzone-pas-3 button:hover {
  background-color: #1565c0;
}


